import * as React from "react";
import { StaticImage } from "gatsby-plugin-image";
import Button, { ButtonVariant } from "../../components/Button";
import { useLayout } from "../../components/Layout/layout-context";
import Quote from "../../components/Quote";
import Text, { TextVariant } from "../../components/Text";
import Layout from "../../components/UseCase/Layout";
import SimpleParagraph from "../../components/UseCase/SimpleParagraph";
import UseCaseCard from "../../components/UseCase/Card";
import SimpleList from "../../components/UseCase/SimpleList";
import LogoCard from "../../components/UseCase/LogoCard";

const CadeauMaestro = () => {
  const [setShowModal] = useLayout();

  const circleClassName = "relative flex mx-2 lg:mx-0";
  const circleContentClassName =
    "z-10 h-12 w-12 lg:h-[88px] lg:w-[88px] flex items-center justify-center m-2 lg:m-4 bg-white rounded-full lg:mt-4";

  return (
    <Layout
      title="Cadeau, Maestro !"
      metaTitle="Cas client Cadeau Maestro"
      description="Découvrez comment nous avons accompagné Cadeau Maestro dans la mise en en place d’une stratégie qui répond à leurs enjeux actuels"
    >
      {/* header */}
      <div className="relative flex overflow-hidden h-27 lg:h-[465px]">
        <div className="absolute right-0 w-[288px] h-27 lg:w-[948px] lg:h-[465px]">
          <StaticImage
            src="../../images/photos/cadeau-maestro-header.png"
            objectFit="cover"
            alt="image d'un cadeau"
          />
        </div>
        <div className="z-20 my-auto w-40 h-[73px] lg:w-[423px] lg:h-[178px] bg-white ml-4 lg:ml-52">
          <StaticImage
            src="../../images/logos/cadeau-maestro.jpg"
            alt="logo cadeau maestro"
            height={170}
            width={415}
            className="mx-auto my-auto"
          />
        </div>
      </div>
      {/* title*/}
      <SimpleParagraph
        title="L'amour dure plus que 3 ans"
        children={
          <Text variant={TextVariant.text1}>
            Cadeau Maestro est une entreprise familiale pensée pour tous ceux
            qui aiment gâter leurs proches. En orientant ses clients vers le
            cadeau idéal, Cadeau Maestro leur permet de signifier leur amour au
            travers d’attentions originales, proposées selon le profil de l’être
            cher.
          </Text>
        }
        className="mt-4 lg:mt-10"
      />

      {/* picture */}
      <div className="flex lg:mt-10">
        <StaticImage
          src="../../images/photos/cadeau-maestro-picture.png"
          alt="image cadeau maestro"
        />
      </div>

      {/* objectifs */}
      <div className="p-4 mx-auto lg:container lg:py-8 lg:mt-10 lg:px-0">
        <div className="lg:grid lg:grid-cols-9">
          <div className="self-center lg:col-span-3 lg:row-span-4">
            <Text variant={TextVariant.h1}>Objectifs du client</Text>
          </div>
          <div className="flex flex-col gap-2 mt-4 lg:gap-8 lg:col-span-6 lg:mt-0">
            <div>
              <div className="flex items-center">
                <div className="w-12 h-12 lg:w-20 lg:h-20">
                  <StaticImage
                    alt="donut nappage"
                    src="../../images/icons/illustration-p1.png"
                  />
                </div>
                <Text variant={TextVariant.text1} className="ml-2">
                  <span className="font-bold">Optimiser</span> l'expérience
                  client actuelle
                </Text>
              </div>
            </div>
            <div>
              <div className="flex items-center">
                <div className="w-12 h-12 lg:w-20 lg:h-20">
                  <StaticImage
                    alt="donut toping"
                    src="../../images/icons/illustration-p2.png"
                  />
                </div>
                <Text variant={TextVariant.text1} className="ml-2">
                  Bénéficier de{" "}
                  <span className="font-bold">conseils personnalisés</span>
                  en UX et en ergonomie
                </Text>
              </div>
            </div>
            <div>
              <div className="flex items-center">
                <div className="w-12 h-12 lg:w-20 lg:h-20">
                  <StaticImage
                    alt="donut magie"
                    src="../../images/icons/illustration-p3.png"
                  />
                </div>
                <Text variant={TextVariant.text1} className="ml-2">
                  Avoir une visibilité sur l'impact de ces optimisations avec
                  une amélioration des <span className="font-bold">KPI</span>
                </Text>
              </div>
            </div>
            <div>
              <div className="flex items-center">
                <div className="w-12 h-12 lg:w-20 lg:h-20">
                  <StaticImage
                    alt="médaille"
                    src="../../images/icons/illustration-p4.png"
                  />
                </div>
                <Text variant={TextVariant.text1} className="ml-2">
                  Être rassurés sur leurs choix en terme de{" "}
                  <span className="font-bold">contenus</span>
                </Text>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* -- Accompagnement -- */}
      <div className="p-4 mt-10 bg-backgroundD1 lg:p-0">
        <div className="lg:container lg:mx-auto lg:px-0 lg:py-4">
          <div>
            <Text variant={TextVariant.h1} className="text-greyUltraLight">
              Un accompagnement sur mesure
            </Text>
            <Text variant={TextVariant.h4} className="mt-2 text-greyUltraLight">
              Afin de répondre à cette demande de façon personnalisée, nous
              avons mis en place des actions individuelles et collaboratives.
            </Text>
          </div>
          {/* avec le client */}
          <div className="gap-4 mt-8 lg:grid lg:grid-cols-9 lg:grid-rows-2">
            <div className="self-center lg:col-span-3 lg:row-span-2">
              <div className="flex flex-row items-center">
                <div className="w-12 h-12 lg:hidden">
                  <StaticImage
                    src="../../images/icons/client-face.png"
                    alt="client face icon"
                  />
                </div>
                <Text
                  variant={TextVariant.h1}
                  className="ml-8 lg:ml-0 text-greyUltraLight"
                >
                  Avec le client
                </Text>
              </div>
              <div className="justify-center hidden mt-8 lg:flex">
                <div className="w-20 h-20 ">
                  <StaticImage
                    src="../../images/icons/client-face.png"
                    alt="client face icon"
                  />
                </div>
              </div>
              <Text
                variant={TextVariant.text1}
                className="mt-4 text-greyUltraLight lg:mt-8"
              >
                Des ateliers collaboratifs originaux auxquels participent les
                différents membres de l’équipe. Les solutions élaborées sont
                ainsi co-construites grâce au rôle de chacun.
              </Text>
            </div>

            <UseCaseCard
              className="mt-4 lg:mt-0 lg:col-span-6"
              icon={
                <StaticImage
                  src="../../images/icons/pop-corn.png"
                  alt="pop corn icon"
                />
              }
              title="Sessions pop-corn"
              text={
                <>
                  Une présentation d’alignement des visions au client et à son
                  équipe :
                  <ul className="list-disc list-inside">
                    <li>
                      des lois d’UX et de psychologie pour mieux comprendre ses
                      utilisateurs
                    </li>
                    <li>un suivi des prochains plans d’actions</li>
                    <li>
                      un visionnage d’extraits Hotjar d’expériences réelles des
                      utilisateurs sur le site et des recommandations associées
                    </li>
                  </ul>
                </>
              }
            />
            <UseCaseCard
              className="mt-4 lg:mt-0 lg:col-span-3"
              icon={
                <StaticImage
                  src="../../images/icons/donut-pen.png"
                  alt="crayon donut icone"
                />
              }
              title="Ateliers d’UX Writing"
              text="Définition d’un guide du ton et de la voix pour ajuster le discours"
            />
            <UseCaseCard
              className="mt-4 lg:mt-0 lg:col-span-3"
              icon={
                <StaticImage
                  src="../../images/icons/donut-cup.png"
                  alt="coupe donut icone"
                />
              }
              title="Plan de suivi des KPI"
              text="Considération de l’évolution des variables clés pour assurer de l’efficacité des process mis en place"
            />
          </div>

          {/* Par l'agence */}
          <div className="gap-4 mt-8 lg:mt-12 lg:grid lg:grid-cols-9 lg:grid-rows-2">
            <div className="self-center lg:hidden">
              <div className="flex flex-row items-center">
                <div className="w-12 h-12">
                  <StaticImage
                    src="../../images/icons/donut-home.png"
                    alt="maison donut icone"
                  />
                </div>
                <Text
                  variant={TextVariant.h1}
                  className="ml-8 text-greyUltraLight"
                >
                  Par l'agence
                </Text>
              </div>

              <Text
                variant={TextVariant.text1}
                className="mt-4 text-greyUltraLight"
              >
                L’équipe Donut Panic concote un programme soigné et ludique pour
                la réussite de votre projet. Résultat ? Des solutions, du
                partage et même quelques bonus !
              </Text>
            </div>
            <UseCaseCard
              className="mt-4 lg:mt-0 lg:col-span-3"
              icon={
                <StaticImage
                  src="../../images/icons/mic2.png"
                  alt="micro icone"
                />
              }
              title="Entretiens utilisateurs"
              text="Recherche qualitative sur les habitudes d’achat des utlisateurs"
            />
            <UseCaseCard
              className="mt-4 lg:mt-0 lg:col-span-3"
              icon={
                <StaticImage
                  src="../../images/icons/people-face.png"
                  alt="visage icone"
                />
              }
              title="Élaboration de personaes"
              text="Construction d’archétypes issus de l’étude du panel clients"
            />
            <div className="self-center hidden lg:block lg:col-span-3 lg:row-span-2">
              <Text variant={TextVariant.h1} className="text-greyUltraLight">
                Par l'agence
              </Text>
              <div className="flex justify-center mt-8">
                <div className="w-20 h-20">
                  <StaticImage
                    src="../../images/icons/donut-home.png"
                    alt="maison donut icone"
                  />
                </div>
              </div>
              <Text
                variant={TextVariant.text1}
                className="mt-8 text-greyUltraLight"
              >
                L’équipe Donut Panic concote un programme soigné et ludique pour
                la réussite de votre projet. Résultat ? Des solutions, du
                partage et même quelques bonus !
              </Text>
            </div>
            <UseCaseCard
              className="mt-4 lg:mt-0 lg:col-span-3"
              icon={
                <StaticImage
                  src="../../images/icons/map.png"
                  alt="coupe donut icone"
                />
              }
              title="Customer Journey Maps"
              text="Définition des étapes des parcours empruntés par les personaes et de leurs points de douleur"
            />
            <UseCaseCard
              className="mt-4 lg:mt-0 lg:col-span-3"
              icon={
                <StaticImage
                  src="../../images/icons/illustration-p1.png"
                  alt="nappage donut icone"
                />
              }
              title="Refonte de l'existant"
              text="Réécriture des mails automatiques et évolution de l’UI des pages principales du site et et de l’application"
            />
          </div>
        </div>
      </div>

      {/* L'effet Donut Panic */}
      <SimpleList
        title="L'effet Donut Panic"
        children={
          <div className="flex flex-col gap-2 lg:gap-8 lg:col-span-6">
            <div className="flex flex-row items-center">
              <div className="w-12 h-12 lg:w-20 lg:h-20">
                <StaticImage
                  alt="montgolfière donut"
                  src="../../images/icons/donut-balloon.png"
                />
              </div>
              <Text variant={TextVariant.text1} className="ml-2">
                Un produit qui se <span className="font-bold">développe</span>
              </Text>
            </div>
            <div className="flex flex-row items-center">
              <div className="w-12 h-12 lg:w-20 lg:h-20">
                <StaticImage
                  alt="cadeau donut"
                  src="../../images/icons/donut-gift.png"
                />
              </div>
              <Text variant={TextVariant.text1} className="ml-2">
                Des <span className="font-bold">conseils personnalisés</span> en
                UX et en ergonomie
              </Text>
            </div>
            <div className="flex flex-row items-center">
              <div className="w-12 h-12 lg:w-20 lg:h-20">
                <StaticImage
                  alt="coupe donut"
                  src="../../images/icons/donut-cup.png"
                />
              </div>
              <Text variant={TextVariant.text1} className="ml-2">
                Un <span className="font-bold">impact</span> réel de ces
                optimisations sur les <span className="font-bold">KPI</span>
              </Text>
            </div>
            <div className="flex flex-row items-center">
              <div className="w-12 h-12 lg:w-20 lg:h-20">
                <StaticImage
                  alt="donut yin yang"
                  src="../../images/icons/donut-yinyang.png"
                />
              </div>
              <Text variant={TextVariant.text1} className="ml-2">
                Des <span className="font-bold">contenus</span> pertinents pour
                votre audience
              </Text>
            </div>
          </div>
        }
        className=""
      />

      {/* chiffres-clefs */}
      <div className="p-4 bg-otherColor3">
        <div className="grid-cols-9 gap-4 lg:container lg:grid lg:px-0">
          <Text variant={TextVariant.h1} className="self-center col-span-3">
            Chiffres clés
          </Text>
          {/* mobile */}
          <div className="flex flex-col gap-2 mt-4 lg:hidden">
            <div className="flex items-center p-2 bg-white rounded-full">
              <Text variant={TextVariant.h4}>+</Text>
              <div className={circleClassName}>
                <div className="absolute w-full h-full">
                  <StaticImage
                    alt="bg1"
                    src="../../images/photos/circle1.png"
                  />
                </div>
                <Text
                  variant={TextVariant.h4}
                  className={circleContentClassName}
                >
                  36
                </Text>
              </div>
              <Text variant={TextVariant.h4}>% de taux de conversion</Text>
            </div>

            <div className="flex items-center p-2 bg-white rounded-full">
              <Text variant={TextVariant.h4}>-</Text>
              <div className={circleClassName}>
                <div className="absolute w-full h-full">
                  <StaticImage
                    alt="bg5"
                    src="../../images/photos/circle5.png"
                  />
                </div>
                <Text
                  variant={TextVariant.h4}
                  className={circleContentClassName}
                >
                  19
                </Text>
              </div>
              <Text variant={TextVariant.h4}>% d’abandon panier</Text>
            </div>

            <div className="flex items-center p-2 bg-white rounded-full">
              <Text variant={TextVariant.h4}>+</Text>
              <div className={circleClassName}>
                <div className="absolute w-full h-full">
                  <StaticImage
                    alt="bg4"
                    src="../../images/photos/circle4.png"
                  />
                </div>
                <Text
                  variant={TextVariant.h4}
                  className={circleContentClassName}
                >
                  25
                </Text>
              </div>
              <Text variant={TextVariant.h4}>% de CA par mois</Text>
            </div>
          </div>
          {/* desktop */}
          <div className="hidden col-span-2 lg:flex bg-white rounded-[32px] flex-col items-center px-4 py-8 gap-4">
            <div className={circleClassName}>
              <div className="absolute w-full h-full">
                <StaticImage alt="bg1" src="../../images/photos/circle1.png" />
              </div>
              <Text variant={TextVariant.h4} className={circleContentClassName}>
                + 36%
              </Text>
            </div>
            <Text variant={TextVariant.h4} className="text-center">
              de taux de conversion
            </Text>
          </div>

          <div className="hidden col-span-2 lg:flex bg-white rounded-[32px] flex-col items-center px-4 py-8 gap-4">
            <div className={circleClassName}>
              <div className="absolute w-full h-full">
                <StaticImage alt="bg5" src="../../images/photos/circle5.png" />
              </div>
              <Text variant={TextVariant.h4} className={circleContentClassName}>
                - 19%
              </Text>
            </div>
            <Text variant={TextVariant.h4} className="text-center">
              d’abandon panier
            </Text>
          </div>

          <div className="hidden col-span-2 lg:flex bg-white rounded-[32px] flex-col items-center px-4 py-8 gap-4">
            <div className={circleClassName}>
              <div className="absolute w-full h-full">
                <StaticImage alt="bg4" src="../../images/photos/circle4.png" />
              </div>
              <Text variant={TextVariant.h4} className={circleContentClassName}>
                + 25%
              </Text>
            </div>
            <Text variant={TextVariant.h4} className="text-center">
              de CA par mois
            </Text>
          </div>
        </div>
      </div>

      {/* L'aventure continue */}
      <SimpleParagraph
        title="L'aventure continue"
        children={
          <Text variant={TextVariant.text1}>
            À ce jour, notre <strong>collaboration</strong> avec Cadeau Maestro
            se poursuit sur cette belle lancée. De{" "}
            <strong>nouvelles missions</strong> nous ont été confiées, signe
            d'un <strong>partenariat</strong> qui, on l'espère, fonctionnera
            encore longtemps.
          </Text>
        }
      />

      {/* témoignage */}
      <div className="p-4 bg-otherColor2">
        <div className="grid-cols-9 gap-4 lg:container lg:grid lg:px-0">
          <Text variant={TextVariant.h1} className="col-span-3 lg:self-center">
            Nos clients témoignent
          </Text>
          <Quote
            className="col-span-6 mt-4 bg-white lg:mt-0"
            picture={
              <StaticImage
                src="../../images/photos/sylvain.png"
                alt="photo de Sylvain"
                className="w-12 h-12 rounded-full lg:w-16 lg:h-16 "
              />
            }
            nameAndTitle={
              <Text variant={TextVariant.h4}>
                Sylvain Bruyère
                <br />
                PDG de Cadeau Maestro
              </Text>
            }
            quote={
              <Text variant={TextVariant.text2}>
                “Donut Panic a fait un travail remarquable dans le cadre de la
                refonte de notre site de e-commerce www.cadeau-maestro.com
                (Prestashop, + 3M€, 300k clients).
                <br />
                <br />
                C’était indispensable pour nous d’avoir un 2ème œil sur le
                travail réalisé sur cette refonte, et son avis a été précieux.
                Quentin a su avoir une écoute bienveillante et intelligente de
                nos problématiques commerciales et de fidélisation, les prendre
                en compte ainsi que les spécificités de notre métier, et
                travailler de concert avec nous pour imaginer un outil plus
                efficace et plus simple à utiliser pour nos clients. De
                nombreuses reco ont déjà été mises en ligne, et d’autres sont en
                cours.
                <br />
                <br />
                Bref, merci à Donut Panic pour ce travail qui nous aidera
                clairement à mieux répondre à nos enjeux actuels.”
              </Text>
            }
          />
        </div>
      </div>

      {/* nos engagements */}
      <SimpleList
        title="Nos engagements"
        children={
          <div className="flex flex-col gap-2 lg:gap-8 lg:col-span-6">
            <div className="flex flex-row items-center">
              <div className="w-8 h-8">
                <StaticImage
                  alt="donut nu"
                  src="../../images/icons/donut-naked.png"
                />
              </div>
              <Text variant={TextVariant.text1} className="ml-2">
                Uniquement des recommandations <strong>activables</strong>
              </Text>
            </div>

            <div className="flex flex-row items-center">
              <div className="w-8 h-8">
                <StaticImage
                  alt="donut nu"
                  src="../../images/icons/donut-naked.png"
                />
              </div>
              <Text variant={TextVariant.text1} className="ml-2">
                Vous inclure dans la conception de votre produit
              </Text>
            </div>
            <div className="flex flex-row items-center">
              <div className="w-8 h-8">
                <StaticImage
                  alt="donut nu"
                  src="../../images/icons/donut-naked.png"
                />
              </div>
              <Text variant={TextVariant.text1} className="ml-2">
                <strong>Transparence</strong> sur les méthodologies utilisées
              </Text>
            </div>
            <div className="flex flex-row items-center">
              <div className="w-8 h-8">
                <StaticImage
                  alt="donut nu"
                  src="../../images/icons/donut-naked.png"
                />
              </div>
              <Text variant={TextVariant.text1} className="ml-2">
                Vous faire monter en compétences
              </Text>
            </div>
            <div className="flex flex-row items-center">
              <div className="w-8 h-8">
                <StaticImage
                  alt="donut nu"
                  src="../../images/icons/donut-naked.png"
                />
              </div>
              <Text variant={TextVariant.text1} className="ml-2">
                Travailler dans un <strong>esprit collaboratif</strong>
              </Text>
            </div>
            <div className="flex flex-row items-center">
              <div className="w-8 h-8">
                <StaticImage
                  alt="donut nu"
                  src="../../images/icons/donut-naked.png"
                />
              </div>
              <Text variant={TextVariant.text1} className="ml-2">
                Prôner le partage et la bonne humeur
              </Text>
            </div>
          </div>
        }
      />

      {/* RDV */}
      <div className="p-4 bg-primary">
        <div className="grid-cols-9 bg-primary lg:grid lg:container lg:px-0">
          <Text
            variant={TextVariant.h1}
            className="self-center col-span-3 text-greyUltraLight"
          >
            Obtenez un rendez-vous gratuit
          </Text>
          <div className="rounded-[32px] mt-4 lg:p-8 bg-white p-4 col-span-6 lg:mt-0">
            <Text variant={TextVariant.text1}>
              L’aventure vous intéresse ? Vous cherchez une{" "}
              <strong>prestation</strong> ? Rejoignez-nous pour élaborer
              ensemble une <strong>solution personnalisée</strong> qui soit
              adaptée à vos besoins.
            </Text>
            <Button
              text="Contactez-nous"
              variant={ButtonVariant.primary}
              className="mx-auto mt-4 lg:mt-8"
              onClick={() => setShowModal(true)}
            />
          </div>
        </div>
      </div>
      {/* d'autres use cases */}
      <div className="p-4">
        <div className="grid-cols-9 lg:grid lg:container lg:px-0 lg:py-10">
          <Text variant={TextVariant.h1} className="self-center col-span-3">
            D’autres Use cases pour ce type de prestation
          </Text>
          <div className="rounded-[32px] mt-4 col-span-6 lg:mt-0">
            <div className="grid grid-cols-2 gap-4">
              <LogoCard linkTo="/client/inventiv-it">
                <StaticImage
                  src="../../images/logos/inventiv-it.png"
                  alt="logo de inventiv-it"
                  objectFit="contain"
                  className="h-24"
                />
              </LogoCard>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default CadeauMaestro;
